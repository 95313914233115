import React from 'react';
import { viewports } from '../../style-vars';

const { mdOrSmaller, smOrSmaller } = viewports;

const WebsiteSpecialStyles = () => (
  <style jsx global>{`
    .website-special {
      position: relative;
      padding: 0 100px;
      margin-bottom: 64px;
    }
    @media ${mdOrSmaller} {
      .website-special {
        padding: 0 5%;
      }
    }
    .website-special .cta-primary {
      margin-top: 20px;
    }
    .website-special__cont {
      position: relative;
    }
    .website-special__cont img {
      max-width: 75% !important;
      left: 15% !important;
      object-fit: scale-down !important;
    }
    .website-special__bg {
      position: absolute;
      right: 0;
      top: 50%;
      width: 100%;
      height: 320px;
      border-bottom-left-radius: 40px;
      border-top-left-radius: 40px;
    }
    @media ${mdOrSmaller} {
      .website-special__bg {
        height: 200px;
      }
    }
    @media ${smOrSmaller} {
      .website-special__bg {
        height: 100px;
      }
    }
    .website-special__card-list {
      display: grid;
      grid-gap: 12px;
      grid-template-columns: 24px 1fr;
      padding: 12px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.07),
        20px 10px 40px rgba(47, 80, 167, 0.1);
      border-radius: 8px;
    }
    @media ${mdOrSmaller} {
      .website-special__card-list {
        max-width: 95%;
        margin: auto;
      }
    }
    .website-special__card-list p {
      font-size: 16px;
      color: #6e7682;
    }
  `}</style>
);

export default WebsiteSpecialStyles;
