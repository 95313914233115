import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography } from 'antd';
import Image from '../ImageQuerys/HeroImages';
import CtaPrimary from '../Buttons/primary-cta';
import check from '../../images/svg/checkmark.svg';
import WebsiteSpecialStyles from './WebsiteSpecialStyles';

const { Title } = Typography;

const WebsiteSpecial = (props) => {
  const { sectionData } = props;
  const { title, subtext, ctaInfo, cardList, img, bgColor } = sectionData;
  return (
    <section className="website-special">
      <Title level={2} className="text-center">
        {title}
        <span className="blue_dot">.</span>
      </Title>
      <p className="text-center">{subtext}</p>
      <Row align="middle" justify="center">
        <CtaPrimary ctaTitle={ctaInfo.ctaTitle} target={ctaInfo.ctaTarget} />
      </Row>
      <div className="website-special__cont">
        <div
          className="website-special__bg"
          style={{ background: `${bgColor}` }}
        />
        <Image imageName={img} />
      </div>
      <Row gutter={[32, 32]}>
        {cardList.map((item, idx) => (
          <Col lg={8} sm={24} key={`cardList__${idx.toString()}`}>
            <div className="website-special__card-list">
              <img src={check} alt="" />
              <p>{item}</p>
            </div>
          </Col>
        ))}
      </Row>

      <WebsiteSpecialStyles />
    </section>
  );
};

WebsiteSpecial.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
};
WebsiteSpecial.defaultProps = {
  sectionData: '',
};

export default WebsiteSpecial;
